import { Container, Image, Navbar, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { Discord, Github, Twitter } from 'react-bootstrap-icons'

const usLogo = require('../assets/images/us-logo.png');

export const Header = () => (
    <header>
        <Navbar fixed="top" className="py-0 w-100">
            <Container fluid className="w-100 d-block">
                <Row>
                    <Col xs={12} className='bg-black text-black py-3 bs-3'>
                        &nbsp;
                    </Col>
                    <Col xs={12} lg={3} className="bg-black py-2 br-bl-1 br-br-1 mx-auto text-center bs-3">
                        <Link to='/'>
                            <Image src={usLogo} height="48"></Image>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </Navbar>
    </header>
)

export const Footer = () => {
    let links = [
        {
            name: 'Discord',
            url: 'https://discord.gg/VB9bQTue25',
            icon: <Discord />
        },
        {
            name: 'GitHub',
            url: 'https://github.com/UmbrielStudios',
            icon: <Github />
        },
        {
            name: 'X (Twitter)',
            url: 'https://twitter.com/UmbrielStudios',
            icon: <Twitter />
        }
    ];

    return (
        <footer className="bg-black text-light py-3">
            <Container className="text-center">
                <Row className="justify-content-center mb-3 g-5 g-md-0">
                    {links.map((link) => (
                        <Col xs={1} key={link.name}>
                            <a className="fs-2" href={link.url} target="_blank" title={link.name} rel="noreferrer">
                                {link.icon}
                            </a>
                        </Col>
                    ))}
                </Row>
                <hr className="w-50 mx-auto" />
                <Row>
                    <Col>
                        <span className="fs-6">
                            &copy;{new Date().getFullYear()} Umbriel Studios, LLC. All rights reserved.<br />
                            All logos, names, and images belong to their respective owners.
                        </span>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}