import { Container, Row, Col } from "react-bootstrap";

const usLogo = require('../assets/images/us-logo.png');

const HomePage = () => {
    return (
        <Container fluid>
            <Row className="justify-content-center">
                <Col xs={10} className="text-center">
                    <img src={usLogo} alt="Umbriel Studios" className="w-75" />
                </Col>
            </Row>
            <Row>
                <Col className="ts-1 text-light text-center py-5 fs-4">
                    Bringing gaming to the next level
                </Col>
            </Row>
        </Container>
    )
}

export default HomePage;